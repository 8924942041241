import objectSchema from '../api/object.graphql';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import {
  getObjectImageUrlOrDefault,
  prepareObject,
  savedLat,
  savedLon
} from '@/provider/utils';
import { ALARM_STATUSES } from '@/modules/objects/compositions/objectStatus';
import { mediaClient } from '@/provider';
import { USER_PROPERTY_GROUP_NAME } from '@/modules/object-types/compositions/constants';
import { abcSort } from '@/compositions/sortBy';

export function useObject(objectId) {
  const { result, load, loading } = useLazyQuery(
    objectSchema.fetch,
    {
      id: objectId
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const prepareObjectGroups = object => {
    if (!object.objectGroups) return object;
    return object.objectGroups.map(i => i.object1.name);
  };

  const prepareCustomObjectProperties = object => {
    return object?.objectProperties
      ?.filter(property =>
        property.key.startsWith(USER_PROPERTY_GROUP_NAME.toLowerCase())
      )
      .sort((a, b) => abcSort(a.property, b.property));
  };

  const item = useResult(result, {}, data => {
    const obj = prepareObject(data.object);
    return {
      ...obj,
      formattedObjectGroups: prepareObjectGroups(data.object),
      color: obj.currentStateColor.value,
      iconSrc: getObjectImageUrlOrDefault(obj.currentStateIcon.value),
      pictureUrl: mediaClient.getImageUrl(obj.infoImage.value),
      lat: savedLat(obj.positionPoint.value.lat),
      lon: savedLon(obj.positionPoint.value.lon),
      alarmed: obj.statusesAlarm.value === ALARM_STATUSES.TRIGGERED,
      customProperties: prepareCustomObjectProperties(data.object)
    };
  });

  return {
    load,
    item,
    loading
  };
}
